import { HTMLChakraProps, Link, Text, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PublisherAuth } from '@/components/ui/shared/headers/PublisherHeader/components/PublisherAuth';
import {
  CoreNavigation,
  CoreNavigationActions,
  ICoreNavigationProps,
  CoreNavigationClose,
  CoreNavigationNav,
  CoreNavigationHamburger,
  CoreNavigationBrand,
} from '@/components/ui/shared/navigations/CoreNavigation/CoreNavigation';
import { useIsClientSide } from '@/hooks/useIsClientSide';
import { StringParam, useQueryParams } from '@/hooks/useQueryParams';
import { useResource, useResourceRelationship } from '@/lib/datx-jsonapi-react';
import { queryThinConferenceBySlug, queryThinConferenceConfigurationRelationship } from '@/queries/thin-conferences';
import { ThinConfiguration } from '@/resources/ThinConfiguration';
import { getSubdomain } from '@/utils/subdomain-matcher';

export interface IPublisherHeaderProps extends HTMLChakraProps<'div'> {
  variant?: ICoreNavigationProps['variant'];
  onLogout: () => void;
}

export const PublisherHeader: FC<IPublisherHeaderProps> = ({ onLogout, variant = 'default', ...rest }) => {
  const { t } = useTranslation();
  const isClientSide = useIsClientSide();

  const host = isClientSide ? window.location.host : '';
  const subdomain = getSubdomain(host);

  const { slug: publisherSlug } = useQueryParams<{ slug: string }>({ slug: StringParam });
  const { data: conference } = useResource(queryThinConferenceBySlug(publisherSlug || subdomain));
  const { data: configuration } = useResourceRelationship<ThinConfiguration>(
    queryThinConferenceConfigurationRelationship(conference?.id)
  );

  return (
    <CoreNavigation
      variant={variant}
      bg={configuration?.navbarBackgroundColor}
      color={configuration?.navbarTextColor}
      {...rest}
    >
      <CoreNavigationHamburger title={t('action.openMenu')} aria-label={t('action.openMenu')} />

      <Flex as="nav" flexDir="row" flex="1" alignItems="center" justifyContent="space-between">
        <CoreNavigationBrand>
          <Link
            href="/"
            py="1.375rem"
            _hover={{ textDecor: 'none' }}
            textDecor="none"
            w="2xl"
            display={{ base: 'none', lg: 'block' }}
          >
            <Text fontSize="1rem" color={configuration?.navbarTextColor} fontWeight={'bold'} w="2xl">
              {conference?.name}
            </Text>
          </Link>
        </CoreNavigationBrand>

        <CoreNavigationNav>
          <CoreNavigationClose title={t('action.closeMenu')} aria-label={t('action.closeMenu')} />

          <CoreNavigationActions>
            <PublisherAuth onLogout={onLogout} />
          </CoreNavigationActions>
        </CoreNavigationNav>
      </Flex>
    </CoreNavigation>
  );
};
