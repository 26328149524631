import { Skeleton, Image, Menu, MenuButton, MenuItem, MenuList, Flex, Button } from '@chakra-ui/react';
import { useResources } from 'datx-jsonapi-react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import UserIcon from '@/assets/icons/ic-user.svg';

import { NavLink } from '@/components/ui/shared/links/NavLink/NavLink';
import useSession from '@/hooks/useSession';
import { queryThinProfilesByUserId } from '@/queries/thin-profiles';

const LoginMenu = () => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Fragment>
      {/* Desktop */}
      <Menu computePositionOnMount variant="navigation" colorScheme="blackAlpha" placement="bottom-end">
        <MenuButton ml="5" display={{ base: 'none', lg: 'block' }}>
          <UserIcon height="1.5rem" />
        </MenuButton>
        <MenuList color="white">
          <NextLink
            href={`/log-in?redirectUrl=${router.asPath === '/log-in' ? '/' : router.asPath}`}
            passHref
            legacyBehavior
          >
            <NavLink as={MenuItem} px={4} isActiveLink={router.asPath.startsWith('/log-in')}>
              {t('action.logIn')}
            </NavLink>
          </NextLink>

          <NextLink href="/create-account" passHref legacyBehavior>
            <NavLink
              as={MenuItem}
              px={4}
              order={{ base: 1, lg: 3 }}
              isActiveLink={router.asPath.startsWith('/create-account')}
            >
              {t('action.createAccount')}
            </NavLink>
          </NextLink>
        </MenuList>
      </Menu>

      {/* Mobile */}
      <Flex display={{ base: 'flex', lg: 'none' }} flexDirection="column">
        <NextLink
          href={`/log-in?redirectUrl=${router.asPath === '/log-in' ? '/' : router.asPath}`}
          passHref
          legacyBehavior
        >
          <NavLink isActiveLink={router.asPath.startsWith('/log-in')}>{t('action.logIn')}</NavLink>
        </NextLink>
        <NextLink href="/create-account" passHref legacyBehavior>
          <NavLink ml={[null, null, 0, 0]} isActiveLink={router.asPath.startsWith('/create-account')}>
            {t('action.createAccount')}
          </NavLink>
        </NextLink>
      </Flex>
    </Fragment>
  );
};

const UserMenu = ({ user, onLogout }) => {
  const { t } = useTranslation();

  const { data: currentUserProfileData } = useResources(queryThinProfilesByUserId(user?.id));

  const currentUserProfile = currentUserProfileData?.[0];

  return (
    <Fragment>
      {/* Desktop */}
      <Menu computePositionOnMount variant="navigation" colorScheme="blackAlpha" placement="bottom-end">
        <MenuButton display={{ base: 'none', lg: 'block' }} ml="5">
          {currentUserProfile?.mediumSquareAvatarUrl ? (
            <Image w="2rem" h="2rem" src={currentUserProfile.mediumSquareAvatarUrl} borderRadius="9999px" />
          ) : (
            <UserIcon height="1.5rem" />
          )}
        </MenuButton>
        <MenuList color="white">
          <MenuItem as={NavLink} px={5} onClick={onLogout} textTransform="lowercase">
            {t('action.logOut')}
          </MenuItem>
        </MenuList>
      </Menu>

      {/* Mobile */}
      <Flex display={{ base: 'flex', lg: 'none' }} flexDirection="column">
        <Button
          as={NavLink}
          py={3}
          ml={{ base: 5, md: 0 }}
          textTransform="lowercase"
          variant="unstyled"
          onClick={onLogout}
          textAlign="left"
        >
          {t('action.logOut')}
        </Button>
      </Flex>
    </Fragment>
  );
};

interface IPublisherAuthProps {
  onLogout: () => void;
}

export const PublisherAuth: FC<IPublisherAuthProps> = ({ onLogout }) => {
  const { user, isSessionLoading } = useSession();

  if (isSessionLoading) {
    return <Skeleton w="24px" h="24px" borderRadius="full" ml={5} />;
  }

  if (!user) {
    return <LoginMenu />;
  }

  return <UserMenu user={user} onLogout={onLogout} />;
};
